import React, { useRef, useState } from "react";
import { Panel } from "components/panel";
import { useForm } from "react-hook-form";
import { Toast } from "components/toast";
import { Spinner } from "@blueprintjs/core";
import { PostEnquireApi } from "services";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import classNames from "classnames";
import ReactGA from "react-ga4";
import "./FormPanel.css";

export function FormPanel() {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showOtherBox, setShowOtherBox] = useState(false);
  const [isOtherBoxValid, setIsOtherBoxValid] = useState(false);
  const [showRecaptchaError, setShowRecaptchaError] = useState(false);
  const captchaRef = useRef(null);
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const atLeastOne = () =>
    getValues("areasOfInterest").length
      ? true
      : "Please tell me if this is too hard.";

  function onChangeShowOtherBox(e) {
    if (e.target.checked === true && e.target.value === "Other") {
      setShowOtherBox(true);
    } else {
      setShowOtherBox(false);
    }
  }

  async function onSubmit(data) {
    setIsOtherBoxValid(false);
    setShowRecaptchaError(false);

    let otherCheckboxIsSelected =
      getValues("areasOfInterest").includes("Other");
    let otherValue = getValues("otherReason");

    if (otherCheckboxIsSelected === true && otherValue === "") {
      setIsOtherBoxValid(true);
      return;
    }

    const token = captchaRef.current.getValue();
    if (token === "") {
      setShowRecaptchaError(true);
      return;
    }

    setDisableSubmitButton(true);
    setShowSpinner(true);

    data.token = token;
    let isSuccessfulApiRequest = await PostEnquireApi(data);
    if (isSuccessfulApiRequest) {
      reset();
      setShowOtherBox(false);
      setIsOtherBoxValid(false);
      captchaRef.current.reset();

      ReactGA.event({
        category: "Brochureware enquiry submission",
        action: "Success",
      });

      toast.custom(
        <Toast
          title="Success!"
          message="Your enquiry has been submitted."
          toastStyle="success"
        />
      );
    } else {
      ReactGA.event({
        category: "Brochureware enquiry submission",
        action: "Failed",
      });

      toast.custom(
        <Toast
          title="An Error Occurred"
          message="Something went wrong, please try again"
          toastStyle="error"
        />
      );
    }
    setShowSpinner(false);
    setDisableSubmitButton(false);
  }

  return (
    <Panel>
      <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
        <div className="input-row">
          <div className="half-a-row">
            <p className="fs17 open fw600">First Name/s</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.firstName?.type === "required",
              })}
              type="text"
              {...register("firstName", { required: true })}
            />
          </div>
          <div className="half-a-row">
            <p className="fs17 open fw600">Last Name</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.lastName?.type === "required",
              })}
              type="text"
              {...register("lastName", { required: true })}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="half-a-row">
            <p className="fs17 open fw600">Job Title</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.jobTitle?.type === "required",
              })}
              type="text"
              {...register("jobTitle", { required: true })}
            />
          </div>
          <div className="half-a-row">
            <p className="fs17 open fw600">Company Name</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.companyName?.type === "required",
              })}
              type="text"
              {...register("companyName", { required: true })}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="half-a-row">
            <p className="fs17 open fw600">Email Address</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.emailAddress?.type === "required",
              })}
              type="text"
              {...register("emailAddress", { required: true })}
            />
          </div>
          <div className="half-a-row">
            <p className="fs17 open fw600">Telephone Number</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.telephoneNumber?.type === "required",
              })}
              type="text"
              {...register("telephoneNumber", { required: true })}
            />
          </div>
        </div>
        <div className="margin-spacing"></div>
        <div className="input-row">
          <div className="full-row">
            <p className="fs17 open fw600">Address</p>
            <p className="dark-text-colour open">Address Line 1</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.addressLine1?.type === "required",
              })}
              type="text"
              {...register("addressLine1", { required: true })}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="full-row">
            <p className="dark-text-colour open">Address Line 2 (optional)</p>
            <input
              className="input-box"
              type="text"
              {...register("addressLine2")}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="full-row">
            <p className="dark-text-colour open">Address Line 3 (optional)</p>
            <input
              className="input-box"
              type="text"
              {...register("addressLine3")}
            />
          </div>
        </div>
        <div className="margin-spacing"></div>
        <div className="input-row">
          <div className="half-a-row">
            <p className="fs17 open fw600">Town/ City</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.townAndCity?.type === "required",
              })}
              type="text"
              {...register("townAndCity", { required: true })}
            />
          </div>
          <div className="half-a-row">
            <p className="fs17 open fw600">Postcode</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.postcode?.type === "required",
              })}
              type="text"
              {...register("postcode", { required: true })}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="half-a-row">
            <p className="fs17 open fw600">Country</p>
            <input
              className={classNames("input-box", {
                "error-border": errors.country?.type === "required",
              })}
              value="United Kingdom"
              type="text"
              {...register("country", { required: true })}
            />
          </div>
        </div>
        <div className="margin-spacing"></div>
        <div className="input-row">
          <div className="full-row">
            <p className="fs17">
              Please select which of the below you would like to be contacted
              regarding
            </p>
          </div>
        </div>

        {errors.areasOfInterest?.type === "validate" && (
          <div className="input-row">
            <div className="full-row">
              <p className="fs17 open error-text">
                *Please select from the options below before submitting
              </p>
            </div>
          </div>
        )}
        <div className="input-row">
          <div className="half-a-row">
            {[
              "More Information on Vision",
              "Pricing",
              "Additional Services",
            ].map((value) => (
              <div
                className="check-box-bottom-padding line-up-checkbox"
                key={value}
              >
                <input
                  type="checkbox"
                  value={value}
                  className="check-box-right-margin checkbox-sizing"
                  {...register("areasOfInterest", {
                    validate: atLeastOne,
                  })}
                />
                <label className="fs17 open">{value}</label>
              </div>
            ))}
          </div>
          <div className="half-a-row">
            <div
              className="check-box-bottom-padding line-up-checkbox"
              key="Integrations"
            >
              <input
                type="checkbox"
                value="Integrations"
                className="check-box-right-margin checkbox-sizing"
                {...register("areasOfInterest", {
                  validate: atLeastOne,
                })}
              />
              <label className="fs17 open">Integrations</label>
            </div>
            <div
              className="check-box-bottom-padding line-up-checkbox"
              key="30 Day Free Trial"
            >
              <input
                type="checkbox"
                value="30 Day Free Trial"
                className="check-box-right-margin checkbox-sizing"
                {...register("areasOfInterest", {
                  validate: atLeastOne,
                })}
              />
              <label className="fs17 open">30 Day Free Trial</label>
            </div>
            <div className="check-box-bottom-padding line-up-checkbox">
              <input
                type="checkbox"
                value="Other"
                className="check-box-right-margin checkbox-sizing"
                {...register("areasOfInterest", {
                  validate: atLeastOne,
                })}
                onChange={onChangeShowOtherBox}
              />
              <label className="fs17 open">
                Other (Please fill in the box below)
              </label>
            </div>
          </div>
        </div>
        <div
          className={classNames("input-row", {
            hide: !showOtherBox,
          })}
        >
          <div className="full-row">
            <input
              className={classNames("input-box other-input-box", {
                "error-border": isOtherBoxValid,
              })}
              type="text"
              {...register("otherReason")}
            />
          </div>
        </div>

        <div className="input-row">
          <div className="full-row content-centered recaptcha-margin-spacing">
            <ReCAPTCHA sitekey={window.env.RECAPTCHASITEKEY} ref={captchaRef} />
          </div>
        </div>
        {showRecaptchaError && (
          <div className="input-row">
            <div className="full-row content-centered">
              <p className="fs17 open error-text">
                *Please complete captcha form
              </p>
            </div>
          </div>
        )}

        <div className="input-row submit-margin-spacing">
          <div className="full-row content-centered">
            <input
              type="submit"
              className="btn blue-btn submit-button museo"
              disabled={disableSubmitButton}
            />
            <div
              className={classNames("spinner-spacing", {
                hide: showSpinner === false,
              })}
            >
              <Spinner size={35} intent="primary" />
            </div>
          </div>
        </div>
      </form>
      <p className="center-text open body-line-height">
        Please note: the details you submit, including your email address/phone
        number, may be used to keep you informed about Logistics UK’s
        products/services/events. If you do not want to receive information of
        this nature, please email{" "}
        <a
          href="mailto: salessupport@logistics.org.uk"
          className="bold-text dark-text-colour"
        >
          salessupport@logistics.org.uk
        </a>
      </p>
    </Panel>
  );
}
