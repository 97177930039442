import React from "react";
import { NavigationBar } from "pages/navigationbar";
import { HomePage } from "pages/home";
import { BenefitsPage } from "pages/benefits";
import { FeaturesPage } from "pages/features";
import { IntegrationsPage } from "pages/integrations";
import { SupportPage } from "pages/support";
import { EnquirePage } from "pages/enquire";
import { MyProvider } from "state/MyProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { VisionUploader } from "pages/visionUploader";
import { FaqsPage } from "pages/faqs";
import { UsefulLinks } from "pages/usefulLinks";
import { TachoInfringementCodesPage } from "pages/tachoInfringementCodes";
import { ErrorPage } from "pages/error";
import ReactGA from "react-ga4";

const router = createBrowserRouter([
  {
    element: <NavigationBar />,
    children: [
      {
        path: "benefits",
        element: <BenefitsPage />,
      },
      {
        path: "features",
        element: <FeaturesPage />,
      },
      {
        path: "integrations",
        element: <IntegrationsPage />,
      },
      {
        path: "support",
        element: <SupportPage />,
      },
      {
        path: "enquire",
        element: <EnquirePage />,
      },
      {
        path: "faqs",
        element: <FaqsPage />,
      },
      {
        path: "useful-links",
        element: <UsefulLinks />,
      },
      {
        path: "tacho-code-key",
        element: <TachoInfringementCodesPage />,
      },
      {
        path: "TachoCodes",
        element: <TachoInfringementCodesPage />,
      },
      {
        path: "vision-uploader",
        element: <VisionUploader />,
      },
      {
        path: "VisionUploader",
        element: <VisionUploader />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

export function App() {
  ReactGA.initialize(window.env.GAENVIRONMENTID);
  
  return (
    <MyProvider>
      <RouterProvider router={router} />
    </MyProvider>
  );
}
